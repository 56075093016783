<template>
  <div class="meta-box">
    <div class="title-container">
      <h3 class="title">{{ $t('promotion.vdayPromo.title') }}</h3>
    </div>

    <!-- action btn -->
    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <p class="desc-title text-center">
          {{ $t('promotion.vdayPromo.desc') }}
        </p>
      </el-col>
      <el-col :xs="24" class="my-4 text-center">
        <img :src="prizeImagePath" alt />
      </el-col>
      <el-col :xs="24">
        <p class="desc-title text-center">
          {{ $t('promotion.vdayPromo.desc2') }}
        </p>
      </el-col>
      <el-col :xs="24" class="mt-4">
        <div class="sub-title">
          {{ $t('promotion.vdayPromo.buttonDesc') }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button class="mb-4 el-button btn-blue long-button" data-testid="opt" @click="goToAppStore">
          {{ $t('promotion.vdayPromo.buttonTitle') }}
        </el-button>
      </el-col>
    </el-row>

    <!-- description -->
    <el-row :gutter="20" class="mt-3 sub-box2">
      <el-col :xs="24">
        <!-- eligibility -->
        <Description useSlotForLiTag>
          <li v-for="(item, index) in eligibilityDescriptions" :key="index">
            {{ item }}
          </li>
        </Description>
      </el-col>
      <el-col :xs="24" class="image-box">
        <a href="https://puprime.onelink.me/O5Jx/VDAYPROMOCLIENTPORTAL" target="_blank">
          <img :src="imagePath" alt />
        </a>
      </el-col>
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.vdayPromo.tnc">
          <template v-slot:link>
            <a :href="tncLink" data-testid="tnc" target="_blank">
              {{ $t('promotion.vdayPromo.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Description from '@/components/promotion/vdayPromo/Description.vue';

export default {
  name: 'FundAccount',
  components: {
    Description
  },
  props: {
    campaignID: {
      type: Number,
      default: 1003
    }
  },
  mixins: [],
  data() {
    return {
      showAgreeTnc: false,
      claimTitle: this.$t('promotion.vdayPromo.claimTitle'),
      claimDescriptions: this.$t('promotion.vdayPromo.claimDescriptions'),
      eligibilityTitle: this.$t('promotion.vdayPromo.eligibilityTitle'),
      eligibilityDescriptions: this.$t('promotion.vdayPromo.eligibleDescriptions'),
      imagePath: null,
      prizeImagePath: require('@/assets/images/promotion/vdayPromo/prize.png')
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    tncLink() {
      return this?.$store?.state?.common?.regulator === 'fsa'
        ? 'https://www.puprime.com/pdf/0528/Valentine_Copy_Trading_Terms_and_Conditions.pdf'
        : 'https://www.puprime.net/pdf/0528/Valentine_Copy_Trading_Terms_and_Conditions_Net.pdf';
    }
  },
  mounted() {
    this.handleResize();
    // Add event listener when the component is mounted
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // Remove event listener before the component is destroyed
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    goToAppStore() {
      window.open('https://puprime.onelink.me/O5Jx/VDAYPROMOCLIENTPORTAL', '_blank');
    },
    handleResize() {
      if (window.innerWidth < 500) {
        this.imagePath = require('@/assets/images/promotion/vdayPromo/vday_desc-small.png');
      } else {
        this.imagePath = require('@/assets/images/promotion/vdayPromo/vday_desc.png');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/vdayPromo.scss';
.image-box {
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: center;
  img {
    width: 100%;
  }
}

/deep/ {
  .btn-box {
    margin-top: 65px;
    text-align: center;
  }
  .el-button {
    min-width: 0 !important;
    font-size: 15px;
    font-weight: 900;
    text-align: center;
    background-color: $future-blue;
    color: white;
    &.is-disabled {
      background-color: $future-blue;
      color: white;
    }
  }
}

@media (max-width: 480px) {
  .image-box {
    img {
      width: 100%;
    }
  }
}

.top {
  .header-text {
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  ol {
    li {
      font-weight: normal;
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
</style>
